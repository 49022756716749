import { gql } from "@apollo/client";
import { HolidaySummaryTab_SpecialEventDetailsFragment } from "@generated/graphql";
import { getLocalizedWeekdays, normalizeToLocalISODate } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { Icon, Link } from "components/shared";
import { Table } from "components/shared/Table";
import { useAuth } from "contexts/AuthProvider";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { AdminMode } from "types/global";
import { getHolidayHeader } from "./utils";

HolidaySummaryTab.fragments = {
  holidayData: gql`
    fragment HolidaySummaryTab_SpecialEventDetails on SpecialEventDetails {
      usHolidays {
        id
        isoDate
        holidays
      }
      byOrganization {
        id
        organization {
          name
          id
        }
        holidaysByWeekday {
          id
          isoDate
          holidays
        }
      }
    }
  `,
};

type Props = {
  organizationData: HolidaySummaryTab_SpecialEventDetailsFragment["byOrganization"];
  holidayData: HolidaySummaryTab_SpecialEventDetailsFragment["usHolidays"];
};

export function HolidaySummaryTab({ organizationData, holidayData }: Props) {
  const columns = useColumns();

  const usHolidayData = formatUSHolidayRow(holidayData);

  return (
    <Table
      columns={columns}
      data={
        usHolidayData.holidaysByWeekday.some(
          ({ holidays }) => holidays.length > 0
        )
          ? [usHolidayData, ...organizationData]
          : organizationData
      }
      className="max-h-[500px] overflow-y-auto text-start"
      verticalDividers
      showPagination={false}
      emptyIcon={<Icon icon="palmTree" size={8} />}
      dataName={"Holidays"}
      loading={false}
      emptyStateSubtitle={"There are no holidays this week."}
    />
  );
}

const formatUSHolidayRow = (
  data: HolidaySummaryTab_SpecialEventDetailsFragment["usHolidays"]
): HolidaySummaryRow => ({
  id: "US Holidays",
  holidaysByWeekday: data,
});

type HolidaySummaryRow = {
  id: string;
  organization?: HolidaySummaryTab_SpecialEventDetailsFragment["byOrganization"][number]["organization"];
  holidaysByWeekday: HolidaySummaryTab_SpecialEventDetailsFragment["usHolidays"];
};

function useColumns<T extends HolidaySummaryRow>(): Column<T>[] {
  const { setAdminMode, setActiveRoute } = useAuth();
  const { selectedDate } = useAdminDashboardContext();
  const localizedWeekdays = useMemo(
    () => getLocalizedWeekdays(normalizeToLocalISODate(selectedDate)),
    [selectedDate]
  );

  return useMemo(() => {
    const organizationColumn: Column<T> = {
      Header: "Organization",
      sortType: "string",
      Cell: ({
        row: {
          original: { organization, id },
        },
      }: CellProps<T>) => {
        if (!organization) return id;

        return (
          <Link
            route={Routes.org.details}
            routeProps={[String(organization.id)]}
            onClick={() => {
              setAdminMode(AdminMode.Managing);
              setActiveRoute(Routes.org.details, [String(organization.id)]);
            }}
          >
            {organization.name}
          </Link>
        );
      },
    };

    const weekdayColumns: Column<T>[] = [1, 2, 3, 4, 5].map((dayNumber) => ({
      Header: getHolidayHeader(localizedWeekdays[dayNumber]),
      disableSortBy: true,
      Cell: ({ row }: CellProps<T>) => (
        <div>
          {row.original.holidaysByWeekday[dayNumber - 1]?.holidays.join(", ") ||
            "-"}
        </div>
      ),
    }));

    return [organizationColumn, ...weekdayColumns];
  }, [setAdminMode, setActiveRoute, localizedWeekdays]);
}
