import { gql } from "@apollo/client";
import {
  DashboardAssignmentRole,
  DetailsCell_DashboardStaffFragment,
} from "@generated/graphql";
import { Routes } from "@utils/routes";
import { Link } from "components/shared";

import { CellProps, Column } from "react-table";

detailsCell.fragments = {
  staff: gql`
    fragment DetailsCell_DashboardStaff on DashboardStaff {
      role
      details {
        cohortDetails
      }
    }
  `,
};

export function detailsCell<
  Fragment extends DetailsCell_DashboardStaffFragment,
>(engagement: { engagementId: string }, timeslot: string): Column<Fragment> {
  return {
    Header: "Details",
    accessor: "details",
    Cell: ({ row: { original } }: CellProps<Fragment>) => {
      return (
        <div className="flex items-center justify-start gap-2">
          <Link
            route={Routes.engagementsDashboard}
            routeProps={[
              `engagementId=${engagement.engagementId}${
                original.role === DashboardAssignmentRole.Is
                  ? "&isSubstitute=true"
                  : ""
              }&timeslot=${timeslot}`,
            ]}
          >
            {original.details?.cohortDetails}
          </Link>
        </div>
      );
    },
  };
}
