import { ObservableQuery, OperationVariables } from "@apollo/client";
import { useCallback, useState } from "react";

// D = Query Data, V = Query Variables, I = Item (usually a fragment)
type Props<D, V extends OperationVariables, I> = {
  loading: boolean;
  fetchMore: ObservableQuery<D, V>["fetchMore"];
  inputResolver: (cursor: number) => V;
  itemResolver: (data: D) => I[];
  idResolver: (item: I) => string;
  dataCombiner: (prev: D, items: I[]) => D;
};

/**
 * Highly recommend passing in your query data, query variable, and item types
 * to the function's generic input to ensure type safety.
 */
export function useInfiniteScroll<D, V extends OperationVariables, I>({
  loading,
  fetchMore,
  inputResolver,
  itemResolver,
  idResolver,
  dataCombiner,
}: Props<D, V, I>) {
  const [cursor, setCursor] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = useCallback(() => {
    if (loading || !hasMore) return;

    fetchMore({
      variables: inputResolver(cursor),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const fetchedItems = itemResolver(fetchMoreResult);
        const existingIds = new Set(itemResolver(prev).map(idResolver));

        const newItems = fetchedItems.filter(
          (item: I) => !existingIds.has(idResolver(item))
        );

        const combinedItems = [...itemResolver(prev), ...newItems];

        setCursor((prev) => prev + fetchedItems.length);

        if (itemResolver(fetchMoreResult).length === 0) {
          setHasMore(false);
        }

        return dataCombiner(prev, combinedItems);
      },
    });
  }, [
    loading,
    hasMore,
    fetchMore,
    inputResolver,
    cursor,
    itemResolver,
    idResolver,
    dataCombiner,
  ]);

  return { loading, fetchMoreData };
}
