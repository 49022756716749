import { Breadcrumb } from "types/global";
import { Routes } from "./routes/routes";

type Options = { current: boolean };

export const breadcrumbs = {
  home: (): Breadcrumb => ({
    name: "Home",
    route: Routes.home,
    icon: "home",
  }),

  organizations: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "Organizations",
    route: Routes.organizations,
    current: options.current,
  }),

  cohorts: (options: Options | undefined = { current: false }): Breadcrumb => ({
    name: "Cohorts",
    route: Routes.cohorts,
    current: options.current,
  }),

  engagements: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "Engagements",
    route: Routes.engagements,
    current: options.current,
  }),

  users: (
    isManagingMode: boolean,
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: isManagingMode ? "Users" : "Teachers",
    route: Routes.users,
    current: options.current,
  }),

  students: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "Students",
    route: Routes.students,
    current: options.current,
  }),

  mySchedule: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "My Schedule",
    route: Routes.mySchedule,
    current: options.current,
  }),

  myAttendance: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "My Attendance",
    route: Routes.myAttendance,
    current: options.current,
  }),

  myPayments: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "My Payments",
    route: Routes.myPayments,
    current: options.current,
  }),

  myProfile: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "My Profile",
    route: Routes.myProfile,
    current: options.current,
  }),

  myPD: (options: Options | undefined = { current: false }): Breadcrumb => ({
    name: "My PD",
    route: Routes.myPD,
    current: options.current,
  }),

  mentorRecordings: (
    options: (Options & { cohortId: string }) | undefined = {
      current: false,
      cohortId: "",
    }
  ): Breadcrumb => ({
    name: "Recordings",
    route: Routes.cohort.mentorRecordings,
    routeProps: [options.cohortId],
    current: options.current,
  }),

  settings: (
    options: Options | undefined = { current: false }
  ): Breadcrumb => ({
    name: "Settings",
    route: Routes.settings.usHolidays,
    current: options.current,
  }),

  dashboard: (): Breadcrumb => ({
    name: "Dashboard",
    route: Routes.home,
    current: true,
  }),
};
