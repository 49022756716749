import { gql } from "@apollo/client";
import {
  StaffingSummaryCell,
  StaffingSummaryRow,
  StaffingSummary_StaffingDetailsFragment,
} from "@generated/graphql";
import { Icon, Table } from "components/shared";
import {
  DashboardCard,
  DashboardSection,
} from "components/shared/DataVisualization";
import { CellProps, Column } from "react-table";
import { StaffingSummaryColumnHeader } from "./components/StaffingSummaryColumnHeader";
import { StaffingSummaryTableBadges } from "./components/StaffingSummaryTableBadges";
import { StaffingSummaryTableCell } from "./components/StaffingSummaryTableCell";

StaffingSummary.fragments = {
  staffingSummary: gql`
    fragment StaffingSummary_StaffingDetails on StaffingDetails {
      staffingSummaryTable {
        weekStartDate
        thirtyDaysBackDate
        ninetyDaysBackDate
        anchorDate
        rows {
          id
          byThisWeek {
            total
            tt
            is
          }
          byLastThirtyDays {
            total
            tt
            is
          }
          byLastNinetyDays {
            total
            tt
            is
          }
        }
      }
    }
  `,
};

type Props = { data?: StaffingSummary_StaffingDetailsFragment };

export function StaffingSummary({ data }: Props) {
  const columns = useColumns(data);

  return (
    <DashboardSection title="Staffing Summary">
      <DashboardCard className="!p-4">
        <Table
          columns={columns}
          data={data?.staffingSummaryTable.rows ?? []}
          className="max-h-[500px] overflow-y-auto text-start"
          verticalDividers
          showPagination={false}
          emptyIcon={<Icon icon="palmTree" size={8} />}
          dataName={"Staffing Summary"}
          loading={false}
        />
      </DashboardCard>
    </DashboardSection>
  );
}

function useColumns<T extends StaffingSummaryRow>(
  data?: StaffingSummary_StaffingDetailsFragment
): Column<T>[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolveDate = (date: any): Date | undefined => {
    if (!date) return undefined;
    return new Date(date);
  };

  const title = {
    id: "id",
    Header: "Title",
    sortType: "string",
    Cell: ({
      row: {
        original: { id },
      },
    }: CellProps<T>) => {
      return <StaffingSummaryTableBadges id={id} />;
    },
  };

  const byThisWeek = {
    id: "byThisWeek",
    Header: (
      <StaffingSummaryColumnHeader
        title={"This Week"}
        startDate={resolveDate(data?.staffingSummaryTable.weekStartDate)}
        endDate={resolveDate(data?.staffingSummaryTable.anchorDate)}
      />
    ),
    sortType: "string",
    Cell: ({
      row: {
        index,
        original: { byThisWeek },
      },
    }: CellProps<T>) => {
      if (index === 0) return renderSpecialCellData(byThisWeek);

      return renderCellData(byThisWeek);
    },
  };

  const byLastThirtyDays = {
    id: "byLastThirtyDays",
    Header: (
      <StaffingSummaryColumnHeader
        title={"Last 30 Days"}
        startDate={resolveDate(data?.staffingSummaryTable.thirtyDaysBackDate)}
        endDate={resolveDate(data?.staffingSummaryTable.anchorDate)}
      />
    ),
    sortType: "string",
    Cell: ({
      row: {
        index,
        original: { byLastThirtyDays },
      },
    }: CellProps<T>) => {
      if (index === 0) return renderSpecialCellData(byLastThirtyDays);
      return renderCellData(byLastThirtyDays);
    },
  };

  const byLastNinetyDays = {
    id: "byLastNinetyDays",
    Header: (
      <StaffingSummaryColumnHeader
        title={"Last 90 Days"}
        startDate={resolveDate(data?.staffingSummaryTable.ninetyDaysBackDate)}
        endDate={resolveDate(data?.staffingSummaryTable.anchorDate)}
      />
    ),
    sortType: "string",
    Cell: ({
      row: {
        index,
        original: { byLastNinetyDays },
      },
    }: CellProps<T>) => {
      if (index === 0) return renderSpecialCellData(byLastNinetyDays);
      return renderCellData(byLastNinetyDays);
    },
  };
  return [title, byThisWeek, byLastThirtyDays, byLastNinetyDays];
}

const renderCellData = (cell: StaffingSummaryCell) => {
  const { total, tt, is } = cell;

  return (
    <StaffingSummaryTableCell total={total} tt={tt ?? null} is={is ?? null} />
  );
};

const renderSpecialCellData = (cell: StaffingSummaryCell) => {
  const { total, tt, is } = cell;

  return (
    <StaffingSummaryTableCell
      total={total}
      tt={tt ?? null}
      is={is ?? null}
      className="bg-slate-500 text-white"
    />
  );
};
