import { DateTimeRange, getWeekRangeInLocalTimeZone } from "@utils/dateTime";
import addDays from "date-fns/addDays";
import isThisWeek from "date-fns/isThisWeek";
import setDay from "date-fns/setDay";
import { getShortReadableDateRangeString } from "helpers/dateText";
import noop from "lodash/noop";
import React, { useEffect } from "react";

type AdminDashboardContextProps = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  selectedDateIsThisWeek: boolean;
  weekRangeLocal: DateTimeRange;
  getWeekRangeString: () => string;
  getWeekDaysRangeString: () => string;
};

const initialState: AdminDashboardContextProps = {
  selectedDate: setDay(new Date(), 3),
  setSelectedDate: noop,
  selectedDateIsThisWeek: true,
  weekRangeLocal: getWeekRangeInLocalTimeZone(new Date()),
  getWeekRangeString: () => "",
  getWeekDaysRangeString: () => "",
};

export const AdminDashboardContext =
  React.createContext<AdminDashboardContextProps>(initialState);

type Props = {
  children: React.ReactNode;
};

export const AdminDashboardProvider = ({ children }: Props) => {
  const [selectedDate, setSelectedDate] = React.useState(
    initialState.selectedDate
  );

  const handleSetSelectedDate = (date: Date) => {
    setSelectedDate(setDay(date, 3));
  };

  const [selectedDateIsThisWeek, setSelectedDateIsThisWeek] = React.useState(
    initialState.selectedDateIsThisWeek
  );
  const [weekRangeLocal, setWeekRangeLocal] = React.useState(
    initialState.weekRangeLocal
  );
  const getWeekRangeString = () =>
    getShortReadableDateRangeString(weekRangeLocal.start, weekRangeLocal.end);
  const getWeekDaysRangeString = () =>
    getShortReadableDateRangeString(
      addDays(weekRangeLocal.start, 1),
      addDays(weekRangeLocal.end, -1)
    );

  useEffect(() => {
    setSelectedDateIsThisWeek(isThisWeek(selectedDate));
    setWeekRangeLocal(getWeekRangeInLocalTimeZone(selectedDate));
  }, [selectedDate]);

  return (
    <AdminDashboardContext.Provider
      value={{
        selectedDate,
        setSelectedDate: handleSetSelectedDate,
        selectedDateIsThisWeek,
        weekRangeLocal,
        getWeekRangeString,
        getWeekDaysRangeString,
      }}
    >
      {children}
    </AdminDashboardContext.Provider>
  );
};

export const useAdminDashboardContext = (): AdminDashboardContextProps => {
  const context = React.useContext(AdminDashboardContext);
  if (context === undefined) {
    throw new Error(
      "useAdminDashboardContext must be used within an AdminDashboardProvider"
    );
  }
  return context;
};
