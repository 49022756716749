import { gql, useQuery } from "@apollo/client";
import {
  EngagementSummaryTab_StartingThisWeekEngagementItemFragment,
  GetAdminDashboardDataQuery,
  GetAdminDashboardDataQueryVariables,
  TeachersSummary_MentorWeeksDetailsFragment,
} from "@generated/graphql";
import { breadcrumbs } from "@utils/breadcrumbs";
import { Page, PageHeader, triggerErrorToast } from "components/shared";
import { LoadingPage } from "components/shared/Loading";
import { useAdminDashboardContext } from "./AdminDashboardProvider";
import { AdminDashboardNavigationCalendars } from "./components/AdminDashboardNavigationCalendars";
import {
  EngagementsSummaryTabs,
  PaymentsSummary,
  SpecialEventsSummary,
  StaffingSummary,
  TeachersSummary,
  WeekSummary,
} from "./sections";
import { EngagementSummaryTab } from "./sections/engagementsSummary/components/tabs/EngagementSummaryTab";
import { RedashDashboards } from "./sections/RedashDashboard";
import { useInfiniteScroll } from "./useInfiniteScroll";

const GET_ADMIN_DASHBOARD_DATA = gql`
  query GetAdminDashboardData($input: AdminDashboardBaseInput!) {
    getAdminDashboardData(input: $input) {
      adminWeeksDetails {
        ...WeekSummary_AdminDashboard
      }
      startingThisWeekEngagements {
        ...EngagementSummaryTab_StartingThisWeekEngagementItem
      }
      teacherWeeksDetails {
        ...TeacherSummary_TeacherWeekDetails
      }
      mentorsWeeksDetails {
        ...TeachersSummary_MentorWeeksDetails
      }
      paymentsWeeksDetails {
        ...PaymentsSummary_PaymentsDetails
      }
      staffingDetails {
        ...StaffingSummary_StaffingDetails
      }
      specialEventDetails {
        ...SpecialEventsSummary_SpecialEventDetails
      }
      managedEngagements {
        ...RedashDashboards_ManagedEngagementItem
      }
    }
  }
  ${EngagementSummaryTab.fragments.startingThisWeekEngagement}
  ${WeekSummary.fragments.weekSummary}
  ${PaymentsSummary.fragments.paymentsSummary}
  ${TeachersSummary.fragments.teachersSummary}
  ${StaffingSummary.fragments.staffingSummary}
  ${SpecialEventsSummary.fragments.specialEventsSummary}
  ${TeachersSummary.fragments.mentorsWeeksDetails}
  ${RedashDashboards.fragments.managedEngagementItems}
`;

export function AdminDashboard() {
  const { selectedDate } = useAdminDashboardContext();
  const { data, loading, fetchMore } = useQuery<
    GetAdminDashboardDataQuery,
    GetAdminDashboardDataQueryVariables
  >(GET_ADMIN_DASHBOARD_DATA, {
    variables: {
      input: { selectedDate: selectedDate.getTime() },
    },
    onError: (error) => {
      console.error("Fetching data failed: ", error.message);
      triggerErrorToast({
        message: "Something went wrong while retrieving the data.",
        sub: error.message,
      });
    },
  });

  const { fetchMoreData: fetchMoreEngagementsData } = useInfiniteScroll<
    GetAdminDashboardDataQuery,
    GetAdminDashboardDataQueryVariables,
    EngagementSummaryTab_StartingThisWeekEngagementItemFragment
  >({
    loading,
    fetchMore,
    inputResolver: (startingThisWeekEngagementsCursor) => ({
      input: {
        selectedDate: selectedDate.getTime(),
        startingThisWeekEngagementsCursor,
      },
    }),
    itemResolver: (data) =>
      data.getAdminDashboardData.startingThisWeekEngagements,
    idResolver: ({ id }) => id,
    dataCombiner: (prev, startingThisWeekEngagements) => ({
      ...prev,
      startingThisWeekEngagements,
    }),
  });

  const { fetchMoreData: fetchMoreMentorsData } = useInfiniteScroll<
    GetAdminDashboardDataQuery,
    GetAdminDashboardDataQueryVariables,
    TeachersSummary_MentorWeeksDetailsFragment
  >({
    loading,
    fetchMore,
    inputResolver: (mentorsWeeksDetailsCursor) => ({
      input: {
        selectedDate: selectedDate.getTime(),
        mentorsWeeksDetailsCursor,
      },
    }),
    itemResolver: (data) => data.getAdminDashboardData.mentorsWeeksDetails,
    idResolver: ({ id }) => id,
    dataCombiner: (prev, mentorsWeeksDetails) => ({
      ...prev,
      mentorsWeeksDetails,
    }),
  });

  const { getAdminDashboardData } = data ?? {};

  const engagements = getAdminDashboardData?.startingThisWeekEngagements;

  return (
    <>
      {loading && <LoadingPage />}
      <Page className="relative gap-y-5 pb-48">
        <PageHeader
          breadcrumbs={[breadcrumbs.home(), breadcrumbs.dashboard()]}
        />
        <AdminDashboardNavigationCalendars className="sticky top-0 z-10 -mx-4 px-4" />

        <WeekSummary data={getAdminDashboardData?.adminWeeksDetails} />

        <EngagementsSummaryTabs
          data={engagements}
          loading={loading}
          fetchMoreData={fetchMoreEngagementsData}
        />
        <TeachersSummary
          teacherWeeksDetails={getAdminDashboardData?.teacherWeeksDetails}
          mentorsWeeksDetails={getAdminDashboardData?.mentorsWeeksDetails}
          loading={loading}
          fetchMoreData={fetchMoreMentorsData}
        />
        <PaymentsSummary data={getAdminDashboardData?.paymentsWeeksDetails} />
        <StaffingSummary data={getAdminDashboardData?.staffingDetails} />
        <SpecialEventsSummary
          data={getAdminDashboardData?.specialEventDetails}
        />
        <RedashDashboards data={getAdminDashboardData?.managedEngagements} />
      </Page>
    </>
  );
}
