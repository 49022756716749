import { gql } from "@apollo/client";
import {
  EngagementNameCell_TutorDashboardEngagementFragment,
  EngagementNameCell_TutorDashboardEventFragment,
} from "@generated/graphql";
import { Routes } from "@utils/routes";
import { Link } from "components/shared";
import { CellProps, Column } from "react-table";
import { InstructionalSupportTabDashboardEventDetails } from "../types";

engagementNameCell.fragments = {
  tutorDashboardEvent: gql`
    fragment EngagementNameCell_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      engagementId
    }
  `,
  tutorDashboardEngagement: gql`
    fragment EngagementNameCell_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      name
    }
  `,
};

type EventDetails = InstructionalSupportTabDashboardEventDetails<
  EngagementNameCell_TutorDashboardEventFragment,
  unknown,
  EngagementNameCell_TutorDashboardEngagementFragment
>;

export function engagementNameCell<D extends EventDetails>(): Column<D> {
  return {
    id: "engagementName",
    Header: "Engagement",
    Cell: ({
      row: {
        original: { engagementId, engagement },
      },
    }: CellProps<D>) => (
      <Link
        route={Routes.engagementsDashboard}
        routeProps={[`engagementId=${engagementId}`]}
      >
        {engagement?.name}
      </Link>
    ),
  };
}
