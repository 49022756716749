import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  tooltipText?: string;
  className?: string;
}>;

export const AbbrTooltip = ({
  tooltipText,
  className = "underline decoration-dashed decoration-gray-400 cursor-help",
  children,
}: Props) =>
  tooltipText ? (
    <abbr className={className} title={tooltipText}>
      {children}
    </abbr>
  ) : (
    <span>{children}</span>
  );
