import { gql, useApolloClient, useSubscription } from "@apollo/client";
import {
  Engagement,
  MentorTeacherDashboardTeacherAttendanceSubscription,
  MentorTeacherDashboardTeacherAttendanceSubscriptionVariables,
} from "@generated/graphql";
import { updateCache } from "sections/EngagementsDashboard/utils";

export const MENTOR_TEACHER_ATTENDANCE_DASHBOARD = gql`
  subscription MentorTeacherDashboardTeacherAttendance($engagementIds: [ID!]!) {
    teacherAttendanceUpdated(engagementIds: $engagementIds) {
      id
      name
      role
      details {
        cohortDetails
        isNoShow
      }
      userId
      cohortId
      attendance {
        status
        automaticallyUpdated
      }
    }
  }
`;

type MentorTeacherDashboardTeacherAttendanceSubscriptionProps = {
  engagementIds: Engagement["id"][];
};

export function MentorTeacherDashboardTeacherAttendanceSubscriptionComponent({
  engagementIds,
}: MentorTeacherDashboardTeacherAttendanceSubscriptionProps) {
  const client = useApolloClient();

  useSubscription<
    MentorTeacherDashboardTeacherAttendanceSubscription,
    MentorTeacherDashboardTeacherAttendanceSubscriptionVariables
  >(MENTOR_TEACHER_ATTENDANCE_DASHBOARD, {
    variables: { engagementIds },
    onData: ({ data }) => {
      if (data.data?.teacherAttendanceUpdated == null) return;

      const getCacheId = (
        result: MentorTeacherDashboardTeacherAttendanceSubscription
      ) => {
        return result.teacherAttendanceUpdated.id;
      };

      updateCache(client, data.data, getCacheId, {
        status: (data) => data.teacherAttendanceUpdated.attendance.status,
        automaticallyUpdated: (data) =>
          data.teacherAttendanceUpdated.attendance.automaticallyUpdated,
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return null;
}
