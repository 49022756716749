import { gql } from "@apollo/client";
import { InstructionalSupportTabDashboardEventDetailsBuilder_TutorDashboardEventFragment } from "@generated/graphql";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { getEventScheduleStatus } from "../../utils";
import { InstructionalSupportTabDashboardEventDetails } from "./types";

instructionalSupportTabDashboardEventDetailsBuilder.fragments = {
  tutorDashboardEvent: gql`
    fragment InstructionalSupportTabDashboardEventDetailsBuilder_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      durationMinutes
      startDateTime
      endDateTime
    }
  `,
};

export function instructionalSupportTabDashboardEventDetailsBuilder<
  D extends
    InstructionalSupportTabDashboardEventDetailsBuilder_TutorDashboardEventFragment,
  C,
  E,
>(
  isDashboardEvent: D,
  cohort: C | undefined,
  engagement: E | undefined,
  currentDate: Date
): InstructionalSupportTabDashboardEventDetails<D, C, E> {
  const startDateTime = new Date(isDashboardEvent.startDateTime);
  const endDateTime = new Date(isDashboardEvent.endDateTime);
  const minutesElapsed = differenceInMinutes(currentDate, startDateTime);
  return {
    id: isDashboardEvent.cacheKey, // Requirement for react-table to key the table.
    ...isDashboardEvent,
    cohort,
    engagement,
    scheduleStatus: getEventScheduleStatus(
      startDateTime,
      endDateTime,
      currentDate
    ),
    minutesElapsed,
    minutesRemaining: isDashboardEvent.durationMinutes - minutesElapsed,
  };
}
