import { AttendanceStatusPillButton } from "components/shared/AttendanceGrades/AttendanceStatusPillButton";
import { StatusConditionalElementFn } from "sections/EngagementsDashboard/types";
import { AttendanceStatus } from "types/global";

type Props = {
  disabled?: boolean;
  attendanceRowId: string;
  status: AttendanceStatus;
  attendanceStatusOptions: AttendanceStatus[];
  onChange: (status: AttendanceStatus) => void;
  statusConditionalElementFn?: StatusConditionalElementFn<AttendanceStatus>;
};

export const AttendanceStatusInput = ({
  status,
  disabled,
  attendanceStatusOptions,
  onChange,
  statusConditionalElementFn,
}: Props) => {
  return (
    <div className="flex gap-x-2 items-center">
      {attendanceStatusOptions.map((attendanceOption) => (
        <AttendanceStatusPillButton
          key={attendanceOption}
          status={attendanceOption}
          isSelected={attendanceOption === status}
          onChange={(status) => !disabled && onChange(status)}
        />
      ))}
      {statusConditionalElementFn?.(status)}
    </div>
  );
};
