import { localStorageUtils } from "@utils/browser";
import { noop } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";

const USER_DASHBOARD_IS_SHOWN_KEY = "userDashboardIsShown";

type UserDashboardContextProps = {
  showISs: boolean;
  setShowISs: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialState: UserDashboardContextProps = {
  showISs: false,
  setShowISs: noop,
};

export const UserDashboardContext =
  createContext<UserDashboardContextProps>(initialState);

type Props = { children: React.ReactNode };

export const UserDashboardDataProvider = ({ children }: Props) => {
  const initialShowISs = Boolean(
    localStorageUtils.getItem(USER_DASHBOARD_IS_SHOWN_KEY)
  );

  const [showISs, setShowISs] = useState<boolean>(initialShowISs);

  useEffect(() => {
    localStorageUtils.setItem(
      USER_DASHBOARD_IS_SHOWN_KEY,
      showISs ? String(showISs) : ""
    );
  }, [showISs]);

  return (
    <UserDashboardContext.Provider value={{ showISs, setShowISs }}>
      {children}
    </UserDashboardContext.Provider>
  );
};

export const useUserDashboardData = (): UserDashboardContextProps => {
  const context = useContext(UserDashboardContext);
  if (!context)
    throw new Error(
      "useUserDashboardData must be used within a UserDashboardDataProvider"
    );
  return context;
};
