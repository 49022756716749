import { clsx } from "clsx";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  gridWidth?: "third" | "half" | "quarter" | "lastThird";
  mouseEnter?: () => void;
  mouseLeave?: () => void;
};

export const DashboardCard = ({
  className,
  children,
  gridWidth,
  mouseEnter,
  mouseLeave,
}: Props) => (
  <div
    className={clsx(
      "flex flex-col w-full shadow h-fit rounded-xl bg-white",
      "p-10 text-center justify-center gap-y-3",
      mouseEnter && "cursor-pointer",
      getGridWidth(gridWidth),
      className
    )}
    onMouseEnter={() => mouseEnter?.()}
    onMouseLeave={() => mouseLeave?.()}
  >
    {children}
  </div>
);

const getGridWidth = (gridWidth?: string) => {
  switch (gridWidth) {
    case "quarter":
      return "col-span-12 sm:col-span-6 lg:col-span-3";
    case "third":
      return "col-span-12 sm:col-span-6 lg:col-span-4";
    case "lastThird":
      return "col-span-12 lg:col-span-4";
    case "half":
      return "col-span-12 lg:col-span-6";
    default:
      return "";
  }
};
