import { gql } from "@apollo/client";
import {
  NameCell_DashboardStaffFragment,
  UserSortableField,
} from "@generated/graphql";
import { Icon } from "components/shared";

import { CellProps, Column } from "react-table";

nameCell.fragments = {
  staff: gql`
    fragment NameCell_DashboardStaff on DashboardStaff {
      name
      userId
    }
  `,
};

export function nameCell<Fragment extends NameCell_DashboardStaffFragment>(
  setSelectedUserId: (userId: string) => void,
  setOpenAttendanceScorecard: (state: boolean) => void
): Column<Fragment> {
  return {
    id: UserSortableField.FullName,
    Header: "Name",
    accessor: "name",
    Cell: ({ row: { original } }: CellProps<Fragment>) => (
      <div
        className="flex items-center gap-2 cursor-pointer group"
        onClick={() => {
          setSelectedUserId(original.userId);
          setOpenAttendanceScorecard(true);
        }}
      >
        <Icon icon="source" size={6} color="text-blue-500" />
        <p className="text-blue-500 font-semibold group-hover:underline">
          {original.name}
        </p>
      </div>
    ),
  };
}
