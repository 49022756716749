import { Icon, Tooltip } from "components/shared";
import { IconType } from "components/shared/Icon";
import { EngagementTypeTab, EventsTypeTab } from "../types";

export const getEngagementTabTitles = (
  dateRangeString: string | undefined,
  isLessThanMdScreen: boolean,
  isLessThanXlScreen: boolean
) => {
  const { startingThisWeek, staffing } = EngagementTypeTab;
  const getTitle = (title: string, tab: EngagementTypeTab) => (
    <Tooltip
      className="relative cursor-pointer"
      content={
        <div className="max-w-[350px] text-center">
          <p>{Object.values(tab)}</p>
          {dateRangeString && <p>{dateRangeString}</p>}
        </div>
      }
    >
      {title}
      <Icon
        icon="info"
        className="absolute top-[-1px] right-[-19px]"
        size={4}
        color="text-inherit"
      />
    </Tooltip>
  );

  const isLarge = !isLessThanMdScreen && !isLessThanXlScreen;

  return [
    getTitle(isLarge ? "Starting this week" : "New", startingThisWeek),

    getTitle(isLessThanMdScreen ? "Staffing" : "In Staffing", staffing),
  ];
};

export const getSpecialEventTabTitles = (isLessThanLgScreen: boolean) => {
  const { Holidays, SpecialEvents } = EventsTypeTab;
  const iconMap = {
    [Holidays]: "palmTree" as IconType,
    [SpecialEvents]: "noShow" as IconType,
  };

  const getTitle = (tab: EventsTypeTab) => (
    <Tooltip
      className="relative w-full h-full flex justify-center items-center cursor-pointer"
      content={<div className="max-w-[350px]">{Object.values(tab)}</div>}
    >
      <Icon icon={iconMap[tab]} size={6} color="text-inherit" />
    </Tooltip>
  );

  return !isLessThanLgScreen
    ? Object.values(EventsTypeTab)
    : Object.values(EventsTypeTab).map((tab) => getTitle(tab));
};
