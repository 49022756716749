import { EmptyStateContainer } from "components/shared/EmptyStateContainer";
import { Icon } from "components/shared/Icon";

export const getDashboardEmptyContainer = (
  roleText: string,
  loading: boolean
) => (
  <EmptyStateContainer
    icon={<Icon icon="cancelCalendar" size={8} />}
    subtitle={`You are not assigned as ${roleText} for any cohort sessions that are scheduled for today.`}
    dataName="Assigned Cohort Sessions"
    loading={loading}
    className="gap-2 p-5"
  />
);
