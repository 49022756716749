import { clsx } from "clsx";
import { ReactNode } from "react";

type Props = {
  title?: ReactNode;
  children: ReactNode;
  className?: string;
  titleDetails?: ReactNode;
  headerClassName?: string;
};

export const DashboardSection = ({
  title,
  children,
  titleDetails,
  className,
  headerClassName,
}: Props) => (
  <div className={clsx("isolate flex flex-col gap-y-4", className)}>
    <div
      className={clsx(
        "flex justify-between items-center w-full px-1",
        headerClassName
      )}
    >
      {title && (
        <h1 className="text-xl sm:text-2xl font-bold text-slate-800">
          {title}
        </h1>
      )}
      {titleDetails && (
        <h4 className="text-lg sm:text-xl font-semibold text-slate-700">
          {titleDetails}
        </h4>
      )}
    </div>
    {children}
  </div>
);
