import clsx from "clsx";
import {
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSaturday,
  isSunday,
} from "date-fns";
import dayjs from "dayjs";

export default function cellRenderer(selectedDate: Date) {
  const renderCell = (current: dayjs.Dayjs, month: dayjs.Dayjs) => {
    const isToday = isSameDay(new Date(), current.toDate());
    const isCurrentWeek =
      isSameWeek(current.toDate(), selectedDate) &&
      !isSunday(current.toDate()) &&
      !isSaturday(current.toDate());
    const isOnSameMonth = isSameMonth(current.toDate(), month.toDate());
    const isInFuture = current.toDate().getTime() > new Date().getTime();

    const getBGColor =
      isToday && isOnSameMonth
        ? "bg-red-500 text-white"
        : isCurrentWeek && isOnSameMonth
        ? "bg-blue-500 text-white"
        : isInFuture
        ? "bg-gray-100"
        : "";

    return (
      <div
        className={clsx(
          "flex relative justify-center h-full w-full",
          getBGColor
        )}
      >
        <p className="z-10 hover:text-inherit">{current.date()}</p>
      </div>
    );
  };

  return renderCell;
}
