import clsx from "clsx";

export const getSubHeaderPillItem = (
  name: string,
  color: string,
  transparent = false
) => (
  <div
    key={`${name}-${color}`}
    className={clsx(
      "flex justify-center items-center text-center !leading-3 flex-nowrap",
      "!text-xs !tracking-normal !px-2 !py-[3px] !font-semibold rounded-full !normal-case",
      transparent && "opacity-0",
      color
    )}
  >
    {name}
  </div>
);

export const renderIndicatorCell = (
  name: string,
  color: string,
  value: number,
  className?: string
) => (
  <div className="relative" key={name}>
    {getSubHeaderPillItem(name, color, true)}
    <div
      className={clsx(
        "flex absolute top-0 left-0 w-full h-full justify-center items-center",
        className
      )}
    >
      <div
        className={clsx(
          "flex justify-center items-center leading-tight w-fit min-w-[26px]",
          "px-[6px] py-[3px] rounded-full text-sm font-semibold",
          color
        )}
      >
        {value}
      </div>
    </div>
  </div>
);

export const renderTooltip = (tip: string) => (
  <div className="!normal-case !text-sm !tracking-normal !font-semibold">
    {tip}
  </div>
);
