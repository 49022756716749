import {
  SlotApplicationStatus,
  StaffingSummaryRowTitle,
} from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared";
import { SlotAppStatusColorMap } from "sections/Jobs/constants";

type badgeInfo = {
  title: string;
  color: string;
};

const titleColorMap: Record<StaffingSummaryRowTitle, badgeInfo> = {
  [StaffingSummaryRowTitle.JobsPosted]: {
    title: "Jobs Posted",
    color: "bg-blue-200 text-blue-900",
  },
  [StaffingSummaryRowTitle.Openings]: {
    title: "Openings",
    color: "bg-purple-200 text-purple-900",
  },
  [StaffingSummaryRowTitle.Applied]: {
    title: "Applications",
    color: "bg-indigo-200 text-indigo-900",
  },
  [StaffingSummaryRowTitle.Withdrawn]: {
    title: "Withdrawals",
    color: "bg-pink-200 text-pink-900",
  },
  [StaffingSummaryRowTitle.Offered]: {
    title: "Offered",
    color: SlotAppStatusColorMap[SlotApplicationStatus.Offered],
  },
  [StaffingSummaryRowTitle.Accepted]: {
    title: "Accepted",
    color: SlotAppStatusColorMap[SlotApplicationStatus.Accepted],
  },
  [StaffingSummaryRowTitle.Expired]: {
    title: "Expired",
    color: SlotAppStatusColorMap[SlotApplicationStatus.Expired],
  },
  [StaffingSummaryRowTitle.Rescinded]: {
    title: "Rescinded",
    color: SlotAppStatusColorMap[SlotApplicationStatus.Rescinded],
  },
  [StaffingSummaryRowTitle.Rejected]: {
    title: "Rejected",
    color: SlotAppStatusColorMap[SlotApplicationStatus.Rejected],
  },
};

type Props = {
  id: StaffingSummaryRowTitle;
  className?: string;
};

export const StaffingSummaryTableBadges = ({ id, className }: Props) => {
  const { title, color } = titleColorMap[id];
  return <Badge className={clsx(color, className)}>{title}</Badge>;
};
