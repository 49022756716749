export enum EngagementTypeTab {
  startingThisWeek = "New Engagements starting this week",
  staffing = "Engagements in staffing",
}

export enum EventsTypeTab {
  Holidays = "Holidays / No Tutoring Days",
  SpecialEvents = "Cancellations / No Show Days / Assessment Days",
}

export enum TeachersTypeTab {
  MTs = "Mentor Teachers",
  ISs = "Instructional Support",
}

export enum StaffType {
  TT = "TT",
  MT = "MT",
  IS = "IS",
  Total = "Total",
}

export const staffTypeColors: { [key in StaffType]: string } = {
  [StaffType.TT]: "text-indigo-900 bg-indigo-200",
  [StaffType.MT]: "text-fuchsia-900 bg-fuchsia-200",
  [StaffType.IS]: "text-cyan-900 bg-cyan-200",
  [StaffType.Total]: "text-white bg-slate-500",
};
