import { SpecialDayType } from "@generated/graphql";

export const eventToColourMap = {
  [SpecialDayType.NoShowDay]: "text-orange-500",
  [SpecialDayType.AssessmentDay]: "text-gray-500",
  [SpecialDayType.CancellationDay]: "text-red-500",
};

export const cohortEventMessageMap = {
  [SpecialDayType.NoShowDay]:
    "This cohort has been marked as a no-show day on this day.",
  [SpecialDayType.AssessmentDay]:
    "This cohort has been marked as an assessment day on this day.",
  [SpecialDayType.CancellationDay]:
    "This cohort has had sessions cancelled on this day.",
};

export const engagementEventMessageMap = {
  [SpecialDayType.NoShowDay]: "This day is a no-show day for all cohorts.",
  [SpecialDayType.AssessmentDay]:
    "This day is an assessment day for all cohorts.",
  [SpecialDayType.CancellationDay]: "N/A",
};
