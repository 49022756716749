import { Popover, Transition } from "@headlessui/react";
import { getWeekNumber } from "@utils/dateTime";
import clsx from "clsx";
import { Icon, NavigationCalendars } from "components/shared";
import { useMemo } from "react";
import { useAdminDashboardContext } from "../../AdminDashboardProvider";
import cellRenderer from "./cellRenderer";

type Props = {
  className?: string;
};

export function AdminDashboardNavigationCalendars({ className }: Props) {
  const { selectedDate, setSelectedDate, getWeekDaysRangeString } =
    useAdminDashboardContext();

  const cellRender = useMemo(() => cellRenderer(selectedDate), [selectedDate]);

  return (
    <Popover
      className={clsx(
        "isolate flex flex-col gap-y-3 items-start px-1 py-3 bg-gray-200/80 backdrop-blur",
        className
      )}
    >
      <Popover.Button className="inline-flex items-center gap-x-1 text-xl sm:text-2xl font-bold text-slate-800">
        <span>
          Week {getWeekNumber(selectedDate)}: {getWeekDaysRangeString()}
        </span>
        <Icon icon="chevronDown" size={6} color="text-slate-800" />
      </Popover.Button>
      <Transition
        as={Popover.Panel}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 -translate-y-1"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 -translate-y-1"
        className="flex flex-row justify-center w-full"
      >
        <NavigationCalendars
          currentWeekShownDate={selectedDate}
          setTargetDateTime={setSelectedDate}
          cellRender={cellRender}
          initCenterDate
        />
      </Transition>
    </Popover>
  );
}
