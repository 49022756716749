import { gql } from "@apollo/client";
import { RedashDashboards_ManagedEngagementItemFragment } from "@generated/graphql";
import { ExternalRoutes } from "@utils/routes/external";
import { ButtonLink } from "components/shared";
import { AbbrTooltip } from "components/shared/AbbrTooltip";
import {
  DashboardCard,
  DashboardSection,
} from "components/shared/DataVisualization";
import { SearchDropdown, SearchSelectOption } from "components/shared/Inputs";
import { useMemo, useState } from "react";
import { useAdminDashboardContext } from "../AdminDashboardProvider";

RedashDashboards.fragments = {
  managedEngagementItems: gql`
    fragment RedashDashboards_ManagedEngagementItem on ManagedEngagementItem {
      id
      name
    }
  `,
};

type Props = { data?: RedashDashboards_ManagedEngagementItemFragment[] };

export function RedashDashboards({ data }: Props) {
  const { getWeekRangeString, selectedDateIsThisWeek } =
    useAdminDashboardContext();

  const [selectedEngagement, setSelectedEngagement] =
    useState<SearchSelectOption | null>(null);

  const options = useMemo(() => {
    return (data ?? []).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [data]);

  const onSelect = (option: SearchSelectOption | null) => {
    setSelectedEngagement(option);
  };

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === selectedEngagement?.value);
  }, [options, selectedEngagement?.value]);

  return (
    <DashboardSection title="Redash Dashboards">
      <DashboardCard className="p-6">
        <div className="flex justify-between">
          <div className="flex flex-col items-start gap-1">
            <h2 className="font-bold text-slate-600 text-[26px]">
              Managed Engagements Dashboard
            </h2>
            <h3 className="font-medium text-slate-600 text-[15px]">
              View all details for an{" "}
              <AbbrTooltip
                tooltipText={
                  selectedDateIsThisWeek
                    ? undefined
                    : `(${getWeekRangeString()})`
                }
              >
                ongoing
              </AbbrTooltip>{" "}
              Engagement
            </h3>
          </div>
          <div className="flex gap-x-2">
            <SearchDropdown
              name={"managed-engagements-dropdown"}
              placeholder="Select Engagement..."
              className="min-w-80 text-left"
              options={options}
              onSelect={onSelect}
              selection={selectedOption}
            />
            <ButtonLink
              disabled={!selectedEngagement}
              openInNewTab
              href={
                selectedEngagement
                  ? ExternalRoutes.redash.managedEngagmentsDashboard.href(
                      selectedEngagement?.value
                    )
                  : ""
              }
            >
              Go
            </ButtonLink>
          </div>
        </div>
      </DashboardCard>
    </DashboardSection>
  );
}
