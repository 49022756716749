import { Routes } from "@utils/routes";
import { Link } from "components/shared";

type Props = {
  revieweeId: string;
  cohortId: string;
  sessionId: string;
};

export function TeacherSurveyLinks(props: Props) {
  return (
    <div className="flex flex-col text-sm ml-[52px]">
      <Link
        className="underline"
        href={Routes.surveys.instructionalQuality.href(props)}
        target="_blank"
      >
        Instructional Quality Feedback
      </Link>
      <Link
        className="underline"
        href={Routes.surveys.professionalExpectations.href(props)}
        target="_blank"
      >
        Professional Expectations Feedback
      </Link>
    </div>
  );
}
