import { getDateRangeFromET } from "@utils/dateTime";
import { AbbrTooltip } from "components/shared/AbbrTooltip";
import { getSubHeaderPillItem } from "components/shared/Table/helpers";

type Props = {
  title: string;
  startDate?: Date;
  endDate?: Date;
};

export const StaffingSummaryColumnHeader = ({
  title,
  startDate,
  endDate,
}: Props) => {
  const datesProvided = startDate && endDate;
  return (
    <div className="flex items-center gap-x-2">
      <div className="text-left">
        <AbbrTooltip
          tooltipText={
            datesProvided ? getDateRangeFromET(startDate, endDate) : ""
          }
        >
          {title}
        </AbbrTooltip>
      </div>
      <div className="flex flex-row gap-2">
        {getSubHeaderPillItem("TT", "text-indigo-900 bg-indigo-200")}
        {getSubHeaderPillItem("IS", "text-cyan-900 bg-cyan-200")}
      </div>
    </div>
  );
};
