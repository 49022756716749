import { gql } from "@apollo/client";
import {
  SpecialDayType,
  SpecialEventSummaryTab_SpecialEventDetailsFragment,
} from "@generated/graphql";
import { getLocalizedWeekdays, normalizeToLocalISODate } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { Icon, Link, Tooltip } from "components/shared";
import { Table } from "components/shared/Table";
import { useAuth } from "contexts/AuthProvider";
import { useMemo } from "react";
import { CellProps, Column } from "react-table";
import { useAdminDashboardContext } from "sections/AdminDashboard/AdminDashboardProvider";
import { AdminMode } from "types/global";
import {
  cohortEventMessageMap,
  engagementEventMessageMap,
  eventToColourMap,
} from "./constants";
import { Cohort, SpecialDay } from "./types";
import { getHolidayHeader } from "./utils";

SpecialEventSummaryTab.fragments = {
  holidayData: gql`
    fragment SpecialEventSummaryTab_SpecialEventDetails on SpecialEventDetails {
      byEngagement {
        id
        engagement {
          name
          id
        }
        specialDaysByWeekday {
          isoDate
          specialDays {
            type
            cohorts {
              name
              id
            }
          }
        }
      }
    }
  `,
};

type Props = {
  engagementData: SpecialEventSummaryTab_SpecialEventDetailsFragment["byEngagement"];
};

export function SpecialEventSummaryTab({ engagementData }: Props) {
  const columns = useColumns();

  return (
    <Table
      columns={columns}
      data={engagementData}
      className="max-h-[500px] overflow-y-auto text-start"
      verticalDividers
      showPagination={false}
      emptyIcon={<Icon icon="palmTree" size={8} />}
      dataName={"Special Events"}
      loading={false}
      emptyStateSubtitle={"There are no special events this week."}
    />
  );
}

function useColumns<
  T extends
    SpecialEventSummaryTab_SpecialEventDetailsFragment["byEngagement"][number],
>(): Column<T>[] {
  const { setAdminMode, setActiveRoute } = useAuth();
  const { selectedDate } = useAdminDashboardContext();
  const localizedWeekdays = useMemo(
    () => getLocalizedWeekdays(normalizeToLocalISODate(selectedDate)),
    [selectedDate]
  );

  return useMemo(() => {
    const engagementColumn = {
      Header: "Engagement",
      sortType: "string",
      Cell: ({
        row: {
          original: { engagement },
        },
      }: CellProps<T>) => {
        const routeProps = [String(engagement.id)];

        return (
          <Link
            route={Routes.engagement.details}
            routeProps={routeProps}
            onClick={() => {
              setAdminMode(AdminMode.Managing);
              setActiveRoute(Routes.engagement.details, routeProps);
            }}
          >
            {engagement.name}
          </Link>
        );
      },
    };

    const weekdayColumns = [1, 2, 3, 4, 5].map((dayNumber) => ({
      Header: getHolidayHeader(localizedWeekdays[dayNumber]),
      disableSortBy: true,
      Cell: ({ row }: CellProps<T>) => {
        const specialDays =
          row.original.specialDaysByWeekday[dayNumber - 1].specialDays;

        if (specialDays.length == 0) return "-";

        const formattedDays = specialDays.map((specialDay) => {
          if (specialDay.cohorts.length === 0)
            return allCohortsToolTip(specialDay);

          const cohorts = specialDay.cohorts.map((cohort) => {
            return singleCohortToolTip(specialDay, cohort, () => {
              () => {
                setAdminMode(AdminMode.Managing);
                setActiveRoute(Routes.cohort.details, [String(cohort.id)]);
              };
            });
          });
          return cohorts;
        });

        return formattedDays;
      },
    }));

    return [engagementColumn, ...weekdayColumns];
  }, [setAdminMode, setActiveRoute, localizedWeekdays]);
}

const allCohortsToolTip = (specialDay: SpecialDay) => {
  return (
    <div
      key={specialDay.type}
      className={`${
        eventToColourMap[specialDay.type as SpecialDayType]
      } font-semibold inline-flex items-center px-1`}
    >
      <Tooltip
        content={engagementEventMessageMap[specialDay.type as SpecialDayType]}
      >
        <Icon
          icon="info"
          size={4}
          color={eventToColourMap[specialDay.type as SpecialDayType]}
        />
      </Tooltip>
      All
    </div>
  );
};

const singleCohortToolTip = (
  specialDay: SpecialDay,
  cohort: Cohort,
  onClick: () => void
) => {
  return (
    <div key={cohort.id} className="inline-flex items-center">
      <Tooltip
        content={cohortEventMessageMap[specialDay.type as SpecialDayType]}
      >
        <Icon
          icon="info"
          size={4}
          color={eventToColourMap[specialDay.type as SpecialDayType]}
        />
      </Tooltip>
      <Link
        route={Routes.cohort.details}
        routeProps={[String(cohort.id)]}
        onClick={onClick}
        className={`${
          eventToColourMap[specialDay.type as SpecialDayType]
        } px-1`}
      >
        {cohort.name}
      </Link>
    </div>
  );
};
