import { gql } from "@apollo/client";
import {
  DashboardAssignmentRole,
  HostPresenceCell_DashboardStaffFragment,
} from "@generated/graphql";
import { destructureCacheKey } from "@utils/strings";
import { Badge, HostAttendanceIndicator, Tooltip } from "components/shared";
import { addMinutes } from "date-fns";
import { CellProps, Column } from "react-table";

hostPresenceCell.fragments = {
  staff: gql`
    fragment HostPresenceCell_DashboardStaff on DashboardStaff {
      role
      cohortId
      details {
        isNoShow
      }
    }
  `,
};

export function hostPresenceCell<
  Fragment extends HostPresenceCell_DashboardStaffFragment,
>(
  engagement: { tracksLiveAttendance: boolean },
  timeslot: string
): Column<Fragment> {
  return {
    Header: () => (
      <span className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-center">
        Host Presence
      </span>
    ),
    width: 210,
    maxWidth: 210,
    accessor: "cohortId",
    Cell: ({ row: { original } }: CellProps<Fragment>) => {
      if (original.role === DashboardAssignmentRole.Is) return null;

      if (!engagement.tracksLiveAttendance || !original.cohortId)
        return (
          <div className="flex items-center justify-center">
            <Badge className="bg-gray-200 text-gray-900 text-center">
              UNAVAILABLE
            </Badge>
          </div>
        );

      const [startTime, duration] = destructureCacheKey(timeslot);
      const sessionStartTime = new Date(Number(startTime));
      const sessionEndTime = addMinutes(sessionStartTime, Number(duration));

      const badgeNoShow = original?.details?.isNoShow && (
        <Tooltip content="This cohort has been identified as a no-show day.">
          <Badge className="bg-purple-200 text-purple-900 text-center">
            NO SHOW
          </Badge>
        </Tooltip>
      );

      const attendanceIndicator = engagement.tracksLiveAttendance &&
        original.cohortId && (
          <HostAttendanceIndicator
            cohortId={original.cohortId}
            cohortSessionStartTime={sessionStartTime}
            cohortSessionEndTime={sessionEndTime}
          />
        );

      return (
        <div className="flex items-center justify-center gap-x-2 w-full">
          {badgeNoShow}
          {attendanceIndicator}
        </div>
      );
    },
  };
}
