import { AccountRole } from "@generated/graphql";
import { UnauthenticatedUser } from "@utils/routes/types";
import { assertUnreachable } from "@utils/types";
import { AuthedLayout } from "components/auth";
import { LoadingPage } from "components/shared/Loading";
import { useAuth } from "contexts/AuthProvider";
import { UserDashboardDataProvider } from "contexts/UserDashboardDataProvider";
import type { NextPage } from "next";
import { useEffect } from "react";
import { AdminDashboard } from "sections/AdminDashboard";
import { AdminDashboardProvider } from "sections/AdminDashboard/AdminDashboardProvider";
import { UserDashboard } from "sections/UserDashboard";
import { AdminMode } from "types/global";

const Home: NextPage = () => {
  const { user, accountRole, isVisitor } = useAuth();
  const { isAdmin, setAdminMode, isDashboardMode } = useAuth();

  useEffect(() => {
    if (isAdmin && !isDashboardMode) setAdminMode(AdminMode.Dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) return <LoadingPage noSidebar />;

  switch (accountRole) {
    case AccountRole.Admin:
      return (
        <AuthedLayout>
          <AdminDashboardProvider>
            <AdminDashboard />
          </AdminDashboardProvider>
        </AuthedLayout>
      );
    case AccountRole.Visitor:
      return (
        <AuthedLayout>
          <LoadingPage noSidebar={isVisitor} />
        </AuthedLayout>
      );
    case AccountRole.MentorTeacher:
    case AccountRole.TutorTeacher:
      return (
        <AuthedLayout>
          <UserDashboardDataProvider>
            <UserDashboard currentUser={user} />
          </UserDashboardDataProvider>
        </AuthedLayout>
      );
    case UnauthenticatedUser.NonAuthedUser:
      return <LoadingPage noSidebar />;
    default:
      assertUnreachable(accountRole);
  }
};

export default Home;
