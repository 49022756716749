import clsx from "clsx";
import { useEffect, useState } from "react";

const TRANSITION_TIME = 100;
const TRANSITION_STEPS = 10;

type Props = { number: number; className?: string; dollarSign?: boolean };

export const DashboardNumber = ({ number, className, dollarSign }: Props) => {
  const [steps, setSteps] = useState<number>(0);
  const [displayNumber, setDisplayNumber] = useState<number>(number);

  useEffect(() => {
    if (number !== displayNumber) {
      const difference = Math.abs(Math.round(displayNumber - number));
      setSteps(difference > TRANSITION_STEPS ? TRANSITION_STEPS : difference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    if (displayNumber === number) setSteps(0);
    if (steps === 0 || displayNumber === number) return;

    let startNumber = displayNumber;
    const stepDuration = Math.round(TRANSITION_TIME / steps);
    const increment = Math.ceil((number - startNumber) / steps);

    const interval = setInterval(() => {
      startNumber += increment;
      const over = increment > 0 && startNumber >= number;
      const under = increment < 0 && startNumber <= number;
      if (over || under) {
        clearInterval(interval);
        startNumber = number;
      }
      setDisplayNumber(startNumber);
    }, stepDuration);

    return () => clearInterval(interval);
  }, [steps, displayNumber, number]);

  return (
    <div
      className={clsx(
        className,
        "w-full justify-center text-center text-5xl font-bold font-roboto text-slate-700 leading-none"
      )}
    >
      {dollarSign ? "$" : ""}
      {displayNumber.toLocaleString()}
    </div>
  );
};
