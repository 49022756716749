import { gql } from "@apollo/client";
import { SpecialEventsSummary_SpecialEventDetailsFragment } from "@generated/graphql";
import { OptionsToggleButton } from "components/shared";
import {
  DashboardCard,
  DashboardSection,
} from "components/shared/DataVisualization";
import { useLayout } from "contexts/LayoutProvider";
import { useState } from "react";
import { getSpecialEventTabTitles } from "../helpers";
import { useDebounce } from "use-debounce";
import { EventsTypeTab } from "../../types";
import { HolidaySummaryTab, SpecialEventSummaryTab } from "./components";

SpecialEventsSummary.fragments = {
  specialEventsSummary: gql`
    fragment SpecialEventsSummary_SpecialEventDetails on SpecialEventDetails {
      ...HolidaySummaryTab_SpecialEventDetails
      ...SpecialEventSummaryTab_SpecialEventDetails
    }
    ${HolidaySummaryTab.fragments.holidayData}
    ${SpecialEventSummaryTab.fragments.holidayData}
  `,
};

type Props = { data?: SpecialEventsSummary_SpecialEventDetailsFragment };

export function SpecialEventsSummary({ data }: Props) {
  const { isLessThanLgScreen } = useLayout();
  const [eventsTab, setEventsTab] = useState<number>(0);
  const [debouncedActiveTab] = useDebounce(eventsTab, 250);

  const getIndex = (value: EventsTypeTab): number =>
    Object.values(EventsTypeTab).indexOf(value);

  return (
    <DashboardSection title="Special Events Summary">
      <OptionsToggleButton
        options={getSpecialEventTabTitles(isLessThanLgScreen)}
        setActiveTab={setEventsTab}
        activeTab={eventsTab}
        titleClassName="text-lg font-semibold"
        height="h-[48px]"
      />
      <DashboardCard className="!p-4">
        {debouncedActiveTab === getIndex(EventsTypeTab.Holidays) && (
          <HolidaySummaryTab
            organizationData={data?.byOrganization ?? []}
            holidayData={data?.usHolidays ?? []}
          />
        )}
        {debouncedActiveTab === getIndex(EventsTypeTab.SpecialEvents) && (
          <SpecialEventSummaryTab engagementData={data?.byEngagement ?? []} />
        )}
      </DashboardCard>
    </DashboardSection>
  );
}
