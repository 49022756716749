import { MentorDashboardQuery } from "@generated/graphql";
import { differenceInMinutes } from "date-fns";
import { EventScheduleStatus } from "types/events";

export const formatTimeslots = (
  timeslots: NonNullable<MentorDashboardQuery["getMentorDashboard"]>,
  currentDate: Date
) => {
  const formattedTimeslots = timeslots.map((timeslot) =>
    formatTimeslot(timeslot, currentDate)
  );

  const sortedTimeslotObjects = formattedTimeslots.sort((a, b) => {
    return (
      Number(b.isOngoing) - Number(a.isOngoing) ||
      Number(b.isUpcoming) - Number(a.isUpcoming) ||
      Number(a.isPast) - Number(b.isPast) ||
      (a.isPast
        ? b.startTime.getTime() - a.startTime.getTime()
        : a.startTime.getTime() - b.startTime.getTime())
    );
  });

  return sortedTimeslotObjects;
};

const formatTimeslot = (
  timeslot: NonNullable<MentorDashboardQuery["getMentorDashboard"][0]>,
  currentDate: Date
) => {
  const startTime = new Date(timeslot.startTime);
  const endTime = new Date(timeslot.endTime);
  const duration = differenceInMinutes(endTime, startTime);
  const isOngoing = currentDate > startTime && currentDate < endTime;
  const isPast = currentDate > endTime;
  const isUpcoming = currentDate < startTime;
  const minutesElapsed = differenceInMinutes(currentDate, startTime);
  const minutesRemaining = duration - minutesElapsed;

  const status = isOngoing
    ? EventScheduleStatus.ONGOING
    : isPast
    ? EventScheduleStatus.CONCLUDED
    : EventScheduleStatus.UPCOMING;

  return {
    startTime,
    endTime,
    minutesElapsed,
    minutesRemaining,
    duration,
    isOngoing,
    isPast,
    isUpcoming,
    timeslot,
    status,
  };
};
