import { MentorDashboardQuery } from "@generated/graphql";
import { Routes } from "@utils/routes";
import { makeCacheKey } from "@utils/strings";
import { IconText, Link, Table } from "components/shared";
import { useState } from "react";
import { Column } from "react-table";
import { SessionReportModal } from "sections/UserDashboard/TutorDashboard/components/TutoringTab/components/SessionReportModal/SessionReportModal";
import { initShowSessionReportModalProps } from "../../constants";
import { ShowSessionReportModalProps } from "../../types";
import {
  attendanceCell,
  detailsCell,
  hostPresenceCell,
  nameCell,
  roleCell,
  sessionCell,
} from "./cells";

type Props = {
  startTime: Date;
  duration: number;
  engagement: MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0];
  setAttendanceId: (id: string) => void;
  setSelectedUserId: (userId: string) => void;
  setTeacherName: (teacherName: string) => void;
  setOpenAttendanceModal: (open: boolean) => void;
  setOpenAttendanceScorecard: (open: boolean) => void;
};

export const MentorDashboardEngagement = ({
  duration,
  startTime,
  engagement,
  setTeacherName,
  setAttendanceId,
  setSelectedUserId,
  setOpenAttendanceModal,
  setOpenAttendanceScorecard,
}: Props) => {
  const [showSessionReportModal, setShowSessionReportModal] =
    useState<ShowSessionReportModalProps>(initShowSessionReportModalProps);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex text-lg">
        <IconText
          icon="engagement"
          child={
            <Link
              route={Routes.engagementsDashboard}
              routeProps={[`engagementId=${engagement.engagementId}`]}
            >
              {engagement.name}
            </Link>
          }
        />
      </div>

      <Table
        showPagination={false}
        columns={getColumns(
          engagement,
          makeCacheKey(startTime.getTime(), duration),
          setSelectedUserId,
          setOpenAttendanceScorecard,
          setOpenAttendanceModal,
          setAttendanceId,
          setTeacherName,
          setShowSessionReportModal
        )}
        className="min-h-0"
        data={engagement.staff}
      />

      <SessionReportModal
        readOnly
        show={showSessionReportModal.show}
        sessionReportId={showSessionReportModal.sessionReportId}
        cohortSessionId={showSessionReportModal.cohortSessionId}
        onClose={() =>
          setShowSessionReportModal({ ...showSessionReportModal, show: false })
        }
      />
    </div>
  );
};

type StaffTrackerRow =
  MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0]["staff"][0];

const getColumns = (
  engagement: MentorDashboardQuery["getMentorDashboard"][0]["engagements"][0],
  timeslot: string,
  setSelectedUserId: (userId: string) => void,
  setOpenAttendanceScorecard: (open: boolean) => void,
  setOpenAttendanceModal: (open: boolean) => void,
  setAttendanceId: (id: string) => void,
  setTeacherName: (teacherName: string) => void,
  setShowSessionReportModal: (value: ShowSessionReportModalProps) => void
) => {
  const columns: Column<StaffTrackerRow>[] = [
    nameCell(setSelectedUserId, setOpenAttendanceScorecard),
    roleCell(),
    hostPresenceCell(engagement, timeslot),
    attendanceCell(setOpenAttendanceModal, setAttendanceId, setTeacherName),
    sessionCell(setShowSessionReportModal),
    detailsCell(engagement, timeslot),
  ];
  return columns;
};
