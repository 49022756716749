import { AccountRole, Engagement } from "@generated/graphql";
import { AdminMode } from "types/global";

const { Dashboard } = AdminMode;
const { Admin } = AccountRole;
const redashBaseURL = "https://web.redash.tutored.live";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExternalRoutes: any = {
  redash: {
    managedEngagmentsDashboard: {
      href: (engagementId: Engagement["id"]) =>
        `${redashBaseURL}/dashboards/18-managed-engagement-dashboard?fullscreen&p_Engagement=${engagementId}`,
      path: () =>
        `${redashBaseURL}/dashboards/18-managed-engagement-dashboard?fullscreen`,
      accountRoleAccess: [Admin],
      adminModeAccess: [Dashboard],
    },
  },
};
