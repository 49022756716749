import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  total: number;
  tt: number | null;
  is: number | null;
  className?: string;
};

export const StaffingSummaryTableCell = ({
  total,
  tt,
  is,
  className,
}: Props) => {
  if (tt == null && is == null)
    return (
      <Badge className={clsx("text-indigo-900 bg-indigo-200", className)}>
        {total}
      </Badge>
    );

  return (
    <div className="flex space-x-3">
      {tt !== null ? (
        <Badge className="text-indigo-900 bg-indigo-200">{tt}</Badge>
      ) : null}
      {is !== null ? (
        <Badge className="text-cyan-900 bg-cyan-200">{is}</Badge>
      ) : null}
    </div>
  );
};
