import { ApolloClient } from "@apollo/client";
import { Modifier } from "@apollo/client/cache";
import { DateTimeRange } from "@utils/dateTime";
import {
  addDays,
  addWeeks,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from "date-fns";
import { TimeFrameFilterMode } from "./types";

export const getDateRangeForEventFilter = (
  timeFrameFilterMode: TimeFrameFilterMode,
  currentDateTime: Date
): DateTimeRange => {
  let start = currentDateTime;
  let end = currentDateTime;

  if (timeFrameFilterMode === "today") {
    start = startOfDay(currentDateTime);
    end = endOfDay(currentDateTime);
  } else if (timeFrameFilterMode === "yesterday") {
    start = startOfDay(subDays(currentDateTime, 1));
    end = endOfDay(subDays(currentDateTime, 1));
  } else if (timeFrameFilterMode === "tomorrow") {
    start = startOfDay(addDays(currentDateTime, 1));
    end = endOfDay(addDays(currentDateTime, 1));
  } else if (timeFrameFilterMode === "thisWeek") {
    start = startOfWeek(currentDateTime);
    end = endOfWeek(currentDateTime);
  } else if (timeFrameFilterMode === "lastWeek") {
    const lastWeekDay = subWeeks(currentDateTime, 1);
    start = startOfWeek(lastWeekDay);
    end = endOfWeek(lastWeekDay);
  } else if (timeFrameFilterMode === "nextWeek") {
    const nextWeekDay = addWeeks(currentDateTime, 1);
    start = startOfWeek(nextWeekDay);
    end = endOfWeek(nextWeekDay);
  } else {
    throw new Error(
      `Invalid event instance filter mode '${timeFrameFilterMode}'`
    );
  }

  return { start, end };
};

type CacheFieldUpdates<TData> = {
  [field: string]: (data: TData) => unknown;
};

export function updateCache<TSubscriptionData>(
  client: ApolloClient<object>,
  subscriptionData: TSubscriptionData,
  cacheIdGetter: (data: TSubscriptionData) => string,
  cacheFields: CacheFieldUpdates<TSubscriptionData>
) {
  const cacheId = cacheIdGetter(subscriptionData);

  // Create a field modifier function for each field
  const fields: { [key: string]: Modifier<unknown> } = {};

  for (const field in cacheFields) {
    fields[field] = () => cacheFields[field](subscriptionData);
  }

  client.cache.modify({
    id: cacheId,
    fields,
  });
}
