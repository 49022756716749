import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { ReactNode } from "react";
import { PlacesType } from "react-tooltip";

type Props = {
  title: React.ReactNode;
  tooltip?: ReactNode;
  place?: PlacesType;
  className?: string;
  titleClassName?: string;
};

export const DashboardTitle = ({
  title,
  tooltip,
  className,
  titleClassName,
  place = "bottom",
}: Props) => {
  return (
    <Tooltip
      className={clsx(
        "flex justify-center items-center w-full gap-x-1 font-bold mb-1 cursor-pointer",
        className
      )}
      content={<div className="max-w-[350px]">{tooltip}</div>}
      tooltipProps={{ place }}
      disabled={!tooltip}
    >
      <div
        className={clsx(
          "relative w-fit text-slate-600 leading-none text-[26px]",
          titleClassName
        )}
      >
        {title}
        {tooltip && (
          <Icon
            icon="info"
            className="absolute top-[-2px] right-[-18px]"
            size={4}
            color="text-slate-500"
          />
        )}
      </div>
    </Tooltip>
  );
};
